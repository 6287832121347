<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="人员"
    hide-query
    hide-delete
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :action="action"
  >
    <template v-slot:edit="{ form }">
      <a-form-model-item label="预警类型" prop="name">
        {{ form.name }}
      </a-form-model-item>

      <a-form-model-item label="权限范围" prop="original_visible_ids">
        <select-book type="book" multiple v-model="form.original_visible_ids"></select-book>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { clone } from "../../common/js/tool";
export default {
  name: "MemberSet",
  data() {
    return {
      url: "/admin/asset-warning-personnel",
      columns: [
        { title: "预警类型", dataIndex: "name" },
        {
          title: "通知人员",
          dataIndex: "original_visible_ids",
          customRender: (text) => {
            let res = "-";
            if (text) {
              let books = JSON.parse(text);
              res = books.map((b, i) => {
                return (
                  <div class="book-item" key={i}>
                    {b.type == "tag" ? (
                      <div class="icon-tag book-icon"></div>
                    ) : (
                      <a-icon class="book-icon" type={b.type == "user" ? "user" : "folder"} />
                    )}
                    {b.type == "tag" ? (
                      <div class="book-name">{b.name}</div>
                    ) : (
                      <open-data class="book-name" type={b.type + "Name"} openid={b.id} />
                    )}
                  </div>
                );
              });
            }
            return res;
          },
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入预警类型", trigger: "blur" }],
        original_visible_ids: [{ required: true, message: "请选择通知人员", trigger: "change" }],
      },
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  created() {},
  methods: {
    beforeEdit(form, fn) {
      if (form.original_visible_ids) {
        form.original_visible_ids = JSON.parse(form.original_visible_ids);
      }
      fn(form);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      let original_visible_ids = res.original_visible_ids;
      if (original_visible_ids) {
        let visible_ids = {
          uids: [],
          dept: [],
          tag: [],
        };
        original_visible_ids?.forEach((item) => {
          if (item.type == "department") {
            visible_ids.dept.push(item.id);
          } else if (item.type == "user") {
            visible_ids.uids.push(item.id);
          } else if (item.type == "tag") {
            visible_ids.tag.push(item.id);
          }
        });
        res.visible_ids = visible_ids;
        res.original_visible_ids = JSON.stringify(original_visible_ids);
      }
      fn(res);
    },
  },
};
</script>

<style lang="less" scoped></style>
